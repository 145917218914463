import { createElement } from "react";
import { Images } from "./images";
import AssetsImage from "../../assets/images/person.png";
export var ImagesContainer = function () {
    var images = [
        { src: AssetsImage, alt: "Imagem localizada dentro de src" },
        {
            src: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
            alt: "Imagem localizada na internet",
        },
        {
            src: "".concat(process.env.PUBLIC_URL, "/images/person.png"),
            alt: "Imagem localizada no diretório público",
        },
    ];
    var props = {
        images: images,
    };
    return createElement(Images, props);
};

import { Home } from "../home/Home";
import { ImagesContainer } from "../images/Images.container";
import { SampleAuthRequest } from "../sample_requests/SampleAuthRequest";
import { SampleRequest } from "../sample_requests/SampleRequest";
import { Profile } from "../settings/Profile";
export var AppRoutes = {
    "/": Home,
    "/profile": Profile,
    "/sample-request": SampleRequest,
    "/sample-auth-request": SampleAuthRequest,
    "/images": ImagesContainer,
};

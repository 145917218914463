var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, Grid, TextField, Button, Container, Typography, } from "@mui/material";
import { useEffect, useState } from "react";
import { useWebgate } from "@xvia/webgate-connect";
import MaskedTextField from "./MaskedTextField";
import axios from "axios";
export var Home = function () {
    var _a = useWebgate(), accessToken = _a.accessToken, initialized = _a.initialized, userInfo = _a.userInfo;
    var _b = useState({
        nome: "",
        cpf: "",
        dataNascimento: "",
        email: "",
        celular: "",
    }), formData = _b[0], setFormData = _b[1];
    useEffect(function () {
        if (initialized && accessToken) {
            if (userInfo) {
                setFormData(__assign(__assign({}, formData), { nome: userInfo.name, cpf: userInfo.preferred_username, email: userInfo.email }));
            }
        }
    }, [accessToken, formData, initialized, userInfo]);
    var handleChange = function (e) {
        var _a;
        setFormData(__assign(__assign({}, formData), (_a = {}, _a[e.target.name] = e.target.value, _a)));
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var backendUrl, response, url, link, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    console.log(formData);
                    backendUrl = "https://portal.demo.xvia.com.br/api/backenddemo/dashboard/generate-pdf";
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post(backendUrl, formData, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            responseType: "blob",
                        })];
                case 2:
                    response = _a.sent();
                    console.log(response.data);
                    url = window.URL.createObjectURL(new Blob([response.data]));
                    link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "certidao-negativa-debitos.pdf");
                    document.body.appendChild(link);
                    link.click();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error("Error", error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Box, { children: _jsx(Card, __assign({ sx: {
                py: 2,
                px: 6,
            } }, { children: _jsx(Grid, __assign({ container: true }, { children: _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Typography, __assign({ align: "center", variant: "h5", gutterBottom: true }, { children: "Emiss\u00E3o Certid\u00E3o Negativa de D\u00E9bitos" })), _jsx("hr", {}), _jsx(Container, __assign({ maxWidth: "sm" }, { children: _jsx("form", __assign({ onSubmit: handleSubmit }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TextField, { fullWidth: true, label: "Nome", name: "nome", value: formData.nome, onChange: handleChange, required: true }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(MaskedTextField, { fullWidth: true, label: "CPF", name: "cpf", mask: "999.999.999-99", value: formData.cpf, onChange: handleChange, required: true }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(MaskedTextField, { fullWidth: true, label: "Data de Nascimento", name: "dataNascimento", mask: "99/99/9999", value: formData.dataNascimento, onChange: handleChange, required: true }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(TextField, { fullWidth: true, label: "E-mail", name: "email", value: formData.email, onChange: handleChange, required: true }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(MaskedTextField, { fullWidth: true, label: "Celular", name: "celular", mask: "(99) 99999-9999", value: formData.celular, onChange: handleChange, required: true }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Button, __assign({ type: "submit", variant: "contained", color: "primary" }, { children: "Solicitar" })) }))] })) })) }))] })) })) })) }));
};

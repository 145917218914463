var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useWebgate } from "@xvia/webgate-connect";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
export var Profile = function () {
    var _a = useWebgate(), accessToken = _a.accessToken, initialized = _a.initialized, userInfo = _a.userInfo;
    var _b = useState(""), token = _b[0], setToken = _b[1];
    var _c = useState({}), user = _c[0], setUser = _c[1];
    useEffect(function () {
        if (initialized && accessToken) {
            setToken(accessToken);
            setUser(userInfo);
        }
    }, [accessToken, initialized, userInfo]);
    return (_jsx(Box, { children: _jsx(Card, __assign({ sx: {
                py: 2,
                px: 6,
            } }, { children: _jsx(Grid, __assign({ container: true }, { children: _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Typography, __assign({ align: "center", variant: "h5", gutterBottom: true }, { children: "Perfil do usu\u00E1rio" })), _jsx("hr", {}), userInfo ? (_jsxs(_Fragment, { children: [user && !!Object.keys(user).length && (_jsxs(Typography, __assign({ component: "div" }, { children: [_jsxs(Box, __assign({ fontWeight: "bold" }, { children: ["Usu\u00E1rio:", " ", _jsx(Box, __assign({ fontWeight: "normal", display: "inline" }, { children: user.name }))] })), _jsxs(Box, __assign({ fontWeight: "bold" }, { children: ["E-mail:", " ", _jsx(Box, __assign({ fontWeight: "normal", display: "inline" }, { children: user.email }))] }))] }))), token && (_jsx(Typography, __assign({ component: "div", style: {
                                        wordWrap: "break-word",
                                    } }, { children: _jsxs(Box, __assign({ fontWeight: "bold" }, { children: ["Token:", " ", _jsx(Box, __assign({ fontWeight: "normal", display: "inline" }, { children: token }))] })) })))] })) : (_jsx(Typography, __assign({ variant: "subtitle1", align: "center" }, { children: "Inicie a sess\u00E3o para obter informa\u00E7\u00F5es do usu\u00E1rio." })))] })) })) })) }));
};

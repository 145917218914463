import { jsx as _jsx } from "react/jsx-runtime";
import { Routes, Route } from "react-router-dom";
import { useMemo } from "react";
import { AppRoutes } from "./routes";
export var RouterManager = function () {
    var routes = useMemo(function () {
        return Object.keys(AppRoutes).map(function (path) {
            var RouteComponent = AppRoutes[path];
            return _jsx(Route, { path: path, element: _jsx(RouteComponent, {}) }, path);
        });
    }, []);
    return _jsx(Routes, { children: routes });
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Card, CardMedia, CardContent, Typography, CardActions, Button, Box, } from "@mui/material";
export var Images = function (_a) {
    var images = _a.images;
    return (_jsx(Box, { children: _jsxs(Card, __assign({ sx: {
                py: 2,
                px: 6,
            } }, { children: [_jsx(Typography, __assign({ align: "center", variant: "h5", gutterBottom: true }, { children: "Imagens" })), _jsx("hr", {}), _jsx(Grid, __assign({ container: true, spacing: 2 }, { children: images.map(function (_a, index) {
                        var alt = _a.alt, src = _a.src;
                        return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4 }, { children: _jsxs(Card, __assign({ sx: {
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: 2,
                                } }, { children: [_jsx(CardMedia, { component: "div", sx: { pt: "56.25%" }, image: src }), _jsxs(CardContent, __assign({ sx: { flexGrow: 1 } }, { children: [_jsx(Typography, __assign({ gutterBottom: true, variant: "h5", component: "h2" }, { children: "Imagem 0".concat(index + 1) })), _jsx(Typography, { children: alt })] })), _jsxs(CardActions, { children: [_jsx(Button, __assign({ size: "small", disabled: true }, { children: "View" })), _jsx(Button, __assign({ size: "small", disabled: true }, { children: "Edit" }))] })] })) }), alt));
                    }) }))] })) }));
};
